export class SvgIcon {
    [key: string]: string
}

export const SVGICONTHEME: SvgIcon = {
    '_exit': 'minimize-2',
    'accessibility': 'accessibility',
    'accessibility-rounded': 'accessibility_rounded',
    'add-700': 'add_700',
    'airline_stops': 'airline_stops',
    'allTypes': 'toustypes',
    'analytics': 'trending_up_rounded',
    'app_redirection': 'app_redirection',
    'archive': 'archiver',
    'arrow-forward-ios-rounded': 'arrow_forward_ios_rounded',
    'arrow-outward-rounded': 'arrow_outward_rounded',
    'arrow-right': 'arrow-right',
    'arrow_back': 'arrow_back',
    'arrow-drop-down-rounded-700': 'arrow_drop_down_rounded_700',
    'arrow_left': 'arrow_left',
    'arrow_left_circle_outline': 'arrow_left_circle_outline',
    'arrow_outward': 'arrow_outward',
    'arrow_right': 'arrow_right',
    'arrow_right_circle_outline': 'arrow_right_circle_outline',
    'article': 'article',
    'article-rounded-fill': 'article_rounded_fill',
    'assignation': 'assignation',
    'assignment': 'playlist_add_check_rounded',
    'assignment_ind': 'assignment_ind',
    'bookmark-added': 'bookmark_added',
    'bookmark-check-fill': 'bookmark_check_fill',
    'brand': 'brand',
    'brand_square': 'brand',
    'button-audio__icon': 'equalizer_1',
    'check': 'check',
    'check-rounded-700': 'check_rounded_700',
    'checked_button': 'check',
    'chevron-right-rounded': 'chevron_right_rounded',
    'class_add': 'add',
    'app-compass-card__footer__button__icon': 'arrow_right_alt_rounded',
    'close': 'cross',
    'close-700': 'close_700',
    'close-rounded': 'close_rounded',
    'close-rounded-700': 'close_rounded_700',
    'contact': 'contact',
    'corpus': 'subscriptions_rounded_fill',
    'cross': 'cross',
    'cross_outline': 'cross_outline',
    'dashboard': 'heart',
    'data_check': 'data_check',
    'delete': 'trash',
    'description': 'description',
    'document': 'document',
    'drag_indicator': 'arrow_down_circle_outline',
    'duplicate': 'duplicate',
    'edit': 'edit',
    'editor-play': 'play_outline',
    'educational-resources': 'overview_key_rounded_fill',
    'equalizer_1': 'equalizer_1',
    'equalizer_2': 'equalizer_2',
    'equalizer_3': 'equalizer_3',
    'equalizer_4': 'equalizer_4',
    'event-rounded-fill': 'event_rounded_fill',
    'event-upcoming-rounded-fill': 'event_upcoming_rounded_fill',
    'expand-arrow': 'arrow_drop_down_700',
    'false': 'false',
    'Faux': 'false',
    'feedback': 'contact',
    'file': 'corpus',
    'file_download': 'file_download',
    'file_text': 'file_text_outline',
    'fill-in-blanks': 'remplir_blancs',
    'for_further': 'crossword_rounded_fill',
    'fullscreen': 'fullscreen_rounded_700',
    'goto': 'goto',
    'group': 'groups_rounded_fill',
    'groups-form-add': 'check',
    'help': 'help',
    'home': 'home_rounded_fill',
    'icon-arrow-back': 'arrow_back',
    'icon_pdf': 'icon_pdf',
    'image__button-fullscreen': 'fullscreen_rounded_700',
    'institution_add': 'add',
    'keyboard-arrow-down-rounded-full': 'keyboard_arrow_down_rounded_full',
    'lessons': 'conversion_path',
    'link': 'url',
    'lock': 'lock',
    'lock-rounded-fill': 'lock_rounded_fill',
    'mat-card-actions__icon-menu-trigger': 'chevron-up',
    'markunread-mailbox-rounded-fill': 'markunread_mailbox_rounded_fill',
    'memory-back-card': 'memory',
    'menu': 'menu',
    'movies': 'movies_outline',
    'next': 'arrow_right_alt_rounded',
    'onboarding_header': 'onboarding_header',
    'open-in-new-rounded': 'open_in_new_rounded',
    'outils2': 'deployed_code_rounded_fill',
    'overview_key': 'overview_key',
    'pdf': 'pdf',
    'pdf_full_name': 'pdf_full_name',
    'play': 'arrow_right',
    'play-in-class': 'play',
    'policy-rounded-fill':'policy_rounded_fill',
    'previous': 'arrow_left_alt_rounded',
    'progression__launch': 'progression__launch',
    'qcm__register__incorrect': 'false',
    'qcm__register__not-correct': 'false',
    'qcm__register__null': 'true',
    'qcm__register__pristine': 'true',
    'quit': 'reply_all_rounded',
    'restart-alt-rounded': 'restart_alt_rounded',
    'retry': 'refresh_rounded_alt',
    'round-videoprojeter': 'round_videoprojeter',
    'round-videoprojeteroff': 'round_videoprojeteroff',
    'save': 'save',
    'school': 'school',
    'shield': 'confi',
    'sound_off': 'sound_off',
    'sound_on': 'sound_on',
    'speaker-equalizer': 'speaker_equalizer',
    'step-done': 'step_done',
    'step-inprogress': 'step_inprogress',
    'step-pasfait': 'step_pasfait',
    'student_add': 'check_circle_outline',
    'tools-circle': 'cube_outline_circle',
    'trainer_add': 'add',
    'transition': 'transition',
    'trash': 'trash',
    'true': 'true',
    'tune-rounded': 'tune_rounded',
    'unarchive': 'desarchiver',
    'unassign': 'unassign',
    'unchecked_button': 'uncheck',
    'unlock': 'unlock',
    'video': 'video',
    'visibility': 'visibility',
    'visibility_cgurgpd': 'cgu',
    'visibility_legalnotice': 'visibility',
    'visibility_privacy': 'privacy',
    'Vrai': 'true',
    'workgroup_add': 'add',
    'xxx': 'dislike',
    'keyboard_tab_right': 'keyboard_tab_right',
    'keyboard_tab_left': 'keyboard_tab_left',
    'forward_to_inbox': 'forward_to_inbox',
    'event_upcoming': 'event_upcoming',
    'enhanced_encryption': 'enhanced_encryption',
    'close_1': 'close_1',
    'add_1': 'add_1',
};